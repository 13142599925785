import { useEffect, useState, useRef, useLayoutEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { getItem, isEmpty } from "utils/helpers";
import FieldError from "shared/organisms/dynamicform/FieldError";
import { getDataFromApi, convertToLabelValueFormat } from "./helpers";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import Ellipsis from "shared/molecules/ellipsis";
import { useWindowResize } from "hooks/useWindowResize";
import { StyledSelect } from "./styles";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { setHierarchyStatus } from "pages/build-test/slices/get-hierarchy-status-slice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataRequest, metadataSuccess } from "app-component/slices/metadata-slice";
import { SearchFieldSingleSelect } from "./searchField";

interface ISingleSelectDependencyFieldProps {
  field: any;
  control: any;
  errors: any;
  setSelectedData: any;
  setValue: any;
  getValues?: any;
  watch?: any;
  indexNumber: any;
  name: any;
}

const SingleSelectDependencySearch = ({
  field,
  control,
  errors,
  setValue,
  getValues,
  watch,
  name,
  indexNumber,
}: ISingleSelectDependencyFieldProps) => {
  const containerRef: any = useRef();
  const { width: screenWidth } = useWindowResize();
  const dispatch = useAppDispatch();
  const multiFormError =
    errors && errors?.[name]?.[indexNumber]?.[field.id]?.message;
  const singleFormError = errors && errors[field.id]?.message;
  const [options, setOptions] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [containerWidth, setContainerWidth] = useState(0);
  const [searchField,setSearchField]=useState("");
  const [triggerOptionList,setTriggerOptionList]=useState(false);
  const { data: metaData, updatedAt } = useAppSelector(
    (state) => state.metadataReducer
  );

  const setOptionsUtility = () => {
    try {
      if (field.isDependent && metaData) {
        const keys: any = Object.keys(field.fieldKeyLists);
        const vals: any = Object.values(field.fieldKeyLists);
        let obj: any = metaData;
        let nextListKey = field.list;
        for (let i = 0; i < keys.length; i++) {
          obj = searchAndGetObj(
            obj,
            watch[vals[i]],
            vals[i],
            keys[i],
            nextListKey
          );
        }
        if (obj && obj[field.list] && obj[field.list].length > 0) {
          const temp = obj[field.list];
          let opts: any = [];
          if (temp && temp.length > 0) {
            opts = convertToLabelValueFormat(field, temp);
            setOptions(opts);
            setSearchOptions(opts);
          }
        } else {
          if(field.onlyApiCall && options.length>0){ // if its dependent and if any value changes it seting options to empty
          }else{
            setOptions([]);
            setSearchOptions([])
          }
        }
      }
      // else if(field.hasApiCall && !field.isDependent){ // here it will call when ever any value in form get update through watch 
      //   const updateMetaData=(listData)=>{
      //     let metadataList = {...metaData};
      //     metadataList[field.list] = listData;
      //     //dispatch(metadataSuccess(metadataList))
      //   }
      //   getDataFromApi(field, watch,updateMetaData).then((data)=>{
      //     setOptions(data)
      //   })
      // } 
      else if(field.onlyApiCall){
        
      }
      else {
        const jsonMetaData = metaData;
        const temp = jsonMetaData[field.list];
        let opts: any = [];
        if (temp && temp.length > 0) {
          opts = convertToLabelValueFormat(field, temp);
          setOptions(opts);
          setSearchOptions(opts);
        }
      }
    } catch (error) {
      console.warn("Set Options Utitlity Error", error);
    }
  };
  

  useEffect(() => {
    if (field?.options?.length > 0) {
      setOptions(field.options);
      return;
    }
    setOptionsUtility();
  
  }, [watch, updatedAt]);

  const hasValue = (value: any) => {
    if (value && Array.isArray(value) && value.length > 0) return true;
    else if (value && typeof value == "string") return true;
    else return false;
  };

  useEffect(() => {
    const updateMetaData=(listData)=>{
      let metadataList = {...metaData};
      metadataList[field.list] = listData;
      dispatch(metadataSuccess(metadataList))
    }
    try {
      if (field?.options?.length > 0) {
        setOptions(field.options);
        return;
      } 
      else if (field.isDependent && watch[field.changeValueId]) {
        dispatch(setHierarchyStatus(true));
        const keys: any = Object.keys(field.fieldKeyLists);
        if (keys.length === 0 && field.hasApiCall) {
          dispatch(metadataRequest())
          getDataFromApi(field, watch,updateMetaData)
            .then((data) => {
              dispatch(setHierarchyStatus(true));
              if (name) {
                const existingValue =
                  watch[field.parentFieldKey][indexNumber][field.id];
                setValue(
                  `${field.parentFieldKey[indexNumber][field.id]}`,
                  existingValue
                );
              }
              if (hasValue(watch[field.changeValueId])) {
                setOptions(data);
                setSearchOptions(data);
                dispatch(setHierarchyStatus(true));
              } else {
                setOptions([]);
                setSearchOptions([]);
              }
            })
            .catch((error) => {
              snackbarRequest({
                message: `${error}...`,
                type: "error",
              });
            });
        }
      }
      else if(field.onlyApiCall ){ // here its has no changed value but it only called one time when itial render
        const updateMetaDummy=(listData)=>{
          let metadataList = {...metaData};
          metadataList[field.list] = listData;
          dispatch(metadataSuccess(metadataList))
        }
        dispatch(metadataRequest())
        getDataFromApi(field, watch,updateMetaDummy).then((data)=>{
          setOptions(data)
        })
      } 
    } catch (error) {
      snackbarRequest({
        message: `${error}...`,
        type: "error",
      });
    }
  }, [watch[field.changeValueId]]);

  useLayoutEffect(() => {
    setContainerWidth(containerRef.current.offsetWidth);
  }, [screenWidth]);

  const handleChange = (event: any, onChange: any) => {
    onChange(event.target.value);

    if (field.dependentFields && !name)
      field.dependentFields.map((item: string) => setValue(`${item}`, ""));

    if (field.dependentFields && name)
      field.dependentFields.map((item: string, idx: any) => {
        setValue(`${name}[${indexNumber}].${item}`, "");
      });
  };

  const hasOptions = options && options.length > 0;

  const handleSearch = async (event) => {
    const searchString: string = event.target.value.toUpperCase();
    setSearchField(event.target.value);
    if (searchString == "") {
      setOptions(searchOptions);
    } else {
      const searchedOptions = await searchOptions.filter((item: any) => {
        if (item.label.toUpperCase().includes(searchString)) {
          return item;
        }
      });

      setOptions(searchedOptions);
    }
  };

  useEffect(()=>{
    //setOptions(searchOptions);
    setOptionsUtility();
  },[triggerOptionList]);

  const styles = {
    labelAsterisk: {
      color: "red",
    },
  };
  return (
    <Grid item xs={12} {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <>
            <FormControl
              sx={{ width: "100%" }}
              error={errors && errors[field.id]?.message}
              size="small"
              ref={containerRef}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color:
                    errors && (singleFormError || multiFormError)
                      ? "#E83845"
                      : "",
                  background: "white",
                }}
              >
                {field.label}
              </InputLabel>

              <StyledSelect
                $readOnly={field.readOnly}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value || ""}
                label={field.label}
                inputProps={{
                  readOnly: field.readOnly ? field.readOnly : false,
                }}
                className={field.className}
                style={field.style}
                error={multiFormError ? multiFormError : singleFormError}
                fullWidth
                required={field.required}
                disabled={field.disabled}
                onChange={(e) => handleChange(e, onChange)}
                MenuProps={{
                  sx: {
                    "&& .MuiList-root": {
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    },
                  },
                }}
              >
                {field.isDependent && !hasOptions && (
                  <MenuItem disabled>
                    {field.dependencyLabel || "No options found"}
                  </MenuItem>
                )}

                {field.enableSearch && (!isEmpty(options) || !isEmpty(searchOptions))  &&(
                  <SearchFieldSingleSelect
                    handleSearch={handleSearch}
                    field={field}
                    setSearchField={setSearchField}
                    searchField={searchField}
                    triggerOptionList={triggerOptionList}
                    setTriggerOptionList={setTriggerOptionList}
                    setSearchOptions={setSearchOptions}
                  />
                )}

                {hasOptions && (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
                {hasOptions &&
                  options.map((option: any) => {
                    return (
                      <MenuItem value={option.value} key={option.value}>
                        <Ellipsis
                          width={`${containerWidth}px`}
                          item={option.label}
                          widthReducer={"50px"}
                        />
                      </MenuItem>
                    );
                  })}
              </StyledSelect>
            </FormControl>
            {field.showError ? (
              <FieldError field={field} errors={errors} />
            ) : (
              field.customizableError
            )}
          </>
        )}
        name={name ? `${name}.${indexNumber}.${field.id}` : field.id}
        control={control}
        defaultValue=""
      />
      {/* {errors &&  errors[field.id]?.message  && (
        <FieldError field={field} errors={errors} />
      )} */}

      {errors && (singleFormError || multiFormError) && (
        <FieldError
          field={field}
          errors={errors}
          name={name}
          indexNumber={indexNumber}
        />
      )}
    </Grid>
  );
};

export default SingleSelectDependencySearch;

const searchAndGetObj = (
  obj: any,
  value: string | number,
  key: string,
  listKey: string,
  nextListKey: string
) => {
  const arr = obj[listKey];
 
  let ob = {};
  if (!arr || arr.length === 0) return ob;
  arr.map((item: any) => {
    if (item[key] === value) {
      ob = item;
    }
  });
  return ob;
};
