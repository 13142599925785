import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Box,
} from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import { makeStyles } from "@mui/styles";
import { objectDuplicationRemover } from "utils/helpers";
import { StyledSelect } from "./styles";
import { useAppSelector } from "hooks/redux";
import { SearchField } from "./search-field";

interface IMultiSelctProps {
  field: any;
  control: any;
  errors: any;
  getValues: any;
  setValue?: any;
  watch?: any;
  nameValue?: any;
  indexNumber?: any;
  name?: any;
}

const useStyles = makeStyles({
  border: {
    "& .css-m1opko-MuiFormLabel-root-MuiInputLabel-root": {
      background: "white",
      paddingRight: "5px",
      paddingLeft: "5px",
    },
  },
});

const MultiSelectDependency = ({
  field,
  control,
  errors,
  setValue,
  getValues,
  watch,
  nameValue,
  indexNumber,
  name,
}: IMultiSelctProps) => {
  const multiFormError =
    errors && errors?.[name]?.[indexNumber]?.[field.id]?.message;
  const singleFormError = errors && errors[field.id]?.message;
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const[flag,setFlag]=useState(false);
  const { data: metaData, updatedAt } = useAppSelector(
    (state) => state.metadataReducer
  );

  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const setDependentFieldsValue = (value: any) => {
    if (field.dependentFlds) {
      let keys: any = Object.keys(field.dependentFlds);
      let vals: any = Object.values(field.dependentFlds);
      if (metaData) {
        let obj: any = metaData;
        let arr: any = obj[keys[0]];
        for (let i = 0; i < keys.length; i++) {
          let valArr = [];
          let nextListKey = field.list;
          if (i + 1 < keys.length) {
            nextListKey = keys[i + 1];
          }

          let ob = getArrays(
            arr,
            vals[i] === field.id ? value : watch[vals[i]],
            vals[i],
            nextListKey
          );
          arr = ob[nextListKey];
          if (vals[i] === field.id) ob["values"] = value;
          // console.log("check ob", ob);
          setValue(`${vals[i]}`, ob.values);
        }
      }
    }
  };

  const handleChange = (event: any, onChange: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
     // if (selected.length === options.length) {  
      if (selected.length === filteredOptions.length) {
        setSelected([]);
        onChange([]);
        setDependentFieldsValue([]);
      } else {
       // const filteredArr = options.map((option: any) => option.value);
        const filteredArr = filteredOptions.map((option: any) => option.value);
        setSelected(filteredArr);
        onChange(filteredArr);
        setDependentFieldsValue(filteredArr);
      }
      return;
    }
    setSelected(value);
    onChange(value);
    setDependentFieldsValue(value);
  };

  useEffect(() => {
    //console.log(field.id,checkFieldKeyIsEmpty(field.fieldKeyLists));
    if (field.isDependent && metaData  ) {
      const keys: any = Object.keys(field.fieldKeyLists);
      const vals: any = Object.values(field.fieldKeyLists);
      let obj: any = metaData;
      for (let i = 0; i < keys.length; i++) {
        let nextListKey = field.list;
        if (i + 1 < keys.length) {
          nextListKey = keys[i + 1];
        }
        if (name) {
          obj = searchAndGetObj(
            obj,
            watch[`${name}`][indexNumber][vals[i]],
            vals[i],
            keys[i],
            nextListKey
          );
        } else {
          obj = searchAndGetObj(
            obj,
            watch[vals[i]],
            vals[i],
            keys[i],
            nextListKey
          );
        }
      }
      if (obj && obj[field.list] && obj[field.list].length > 0) {
        const temp = obj[field.list];
        let opts: any = [];
        if (temp && temp.length > 0) {
          if (field.sort) {
            const sortDropdown = field.sort(temp);
            opts = sortDropdown.map((item: any) => ({
              label: item[field.keyValue.label],
              value: item[field.keyValue.value],
            }));
            setOptions(opts);
          } else {
            opts = temp.map((item: any) => ({
              label: item[field.keyValue.label],
              value: item[field.keyValue.value],
            }));
            setOptions(opts);
          }
        }
      } else {
        setOptions([]);
      }
    } else if (metaData) {
      const jsonMetaData = metaData;
      const temp = jsonMetaData[field.list];
      let opts: any = [];
      if (temp && temp.length > 0) {
        opts = temp.map((item: any) => ({
          label: item[field.keyValue.label],
          value: item[field.keyValue.value],
        }));
        setOptions(opts);
      }
    }
  }, [watch, updatedAt]);

  const hasOptions = filteredOptions && filteredOptions.length > 0;

  const classes = useStyles();
  useEffect(() => {
   // setFilteredOptions(objectDuplicationRemover(options, "value"));  
    if (inputValue?.length > 0) {
      const searchedOptions = options.filter((option: any) => {
        if (option?.label.toLowerCase().includes(inputValue.toLowerCase())) {
          return option;
        }
      });
      setFilteredOptions(objectDuplicationRemover(searchedOptions, "value"));
    } else {
      setFilteredOptions(objectDuplicationRemover(options, "value"));
    }
  }, [options,flag]);

  const handleSearchChange = (event) => {
    const { value: searchValue } = event.target;
    if (searchValue?.length > 0) {
      const searchedOptions = options.filter((option: any) => {
        if (option?.label.toLowerCase().includes(searchValue.toLowerCase())) {
          return option;
        }
      });
      setFilteredOptions(objectDuplicationRemover(searchedOptions, "value"));
    } else {
      setFilteredOptions(objectDuplicationRemover(options, "value"));
    }
    setInputValue(searchValue);
  };

  const handleKeyDown = (event) => {
    event.preventDefault(); 
  };

  const checkValuesInArray = (filteredOptions, valuesArray): boolean => {
    const filteredValues = filteredOptions.map((option) => option.value);
    return filteredValues.every((value) => valuesArray.includes(value));
  };

  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <>
            <FormControl
              style={{ width: "100%" }}
              size="small"
              className={classes.border}
            >
              <InputLabel
                sx={{
                  color:
                    errors && (singleFormError || multiFormError)
                      ? "#E83845"
                      : "",
                  background: "white",
                }}
                // sx={{ background: "white" }}
                id="demo-multiple-checkbox-label"
              >
                {field.label}
              </InputLabel>
              <StyledSelect
                $readOnly={field.readOnly}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={value || []}
                required={field.required}
                disabled={field.disabled}
                style={field.style}
                error={multiFormError ? multiFormError : singleFormError}
                multiple
                inputProps={{
                  readOnly: field.readOnly ? field.readOnly : false,
                }}
                onChange={(event) => {
                  handleChange(event, onChange);
                }}
                renderValue={(selected: any) => {
                  const names = options.reduce((acc: string[], item: any) => {
                    if (value.includes(item.value))
                      return (acc = [...acc, item.label]);
                    return acc;
                  }, []);
                  const namesLength: any =
                    names.length > 1 ? `+${names.length - 1}` : "";
                  return names
                    .join(", ")
                    .replace(/(.{15})..+/, `$1...${namesLength}`);
                }}
                MenuProps={{
                  onKeyDown:{handleKeyDown},
                  autoFocus: true,
                  sx: {
                    "&& .MuiList-root": {
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "auto",
                      // margin: "10px",
                    },
                    "&& .MuiMenuItem-root": {
                      // padding: "8px",
                      width: "100%",
                    },
                  },
                }}
                name={name ? `${name}.${indexNumber}.${field.id}` : field.id}
              >
                {field.isDependent && !hasOptions && (
                  <MenuItem disabled>
                    {field.dependencyLabel || "No options found"}
                  </MenuItem>
                )}
                {options?.length>0 && 
                <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                  {/* <TextField
                  label="search"
                  variant="outlined"
                  value={inputValue}
                  onChange={handleSearchChange}
                  onClick={(e) => e.stopPropagation()} 
                  fullWidth
                  autoFocus
                /> */}
                   <SearchField
                      inputValue={inputValue}
                      flag={flag}
                      setFlag={setFlag}
                      setInputValue={setInputValue}
                      handleSearchChange={handleSearchChange}
                    />
                </MenuItem>
                }
                {hasOptions && (
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: "" }}
                        checked={checkValuesInArray(filteredOptions, value)} 
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: "" }}
                      primary="Select All"
                    />
                  </MenuItem>
                )}
                {hasOptions &&
                  filteredOptions.map((option: any) => (
                   // <div key={option.value} >
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={
                          value ? value.indexOf(option.value) > -1 : false
                        }
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                   // </div>
                  ))}
              </StyledSelect>
            </FormControl>
            {field.showError ? (
              <FieldError field={field} errors={errors} />
            ) : (
              field.customizableError
            )}
          </>
        )}
        name={name ? `${name}.${indexNumber}.${field.id}` : field.id}
        control={control}
        defaultValue=""
      />
      {/* {errors && errors[field.id]?.message  && (
        <FieldError field={field} errors={errors} />
      )} */}

      {errors && (singleFormError || multiFormError) && (
        <FieldError
          field={field}
          errors={errors}
          name={name}
          indexNumber={indexNumber}
        />
      )}
    </Grid>
  );
};

export default MultiSelectDependency;

const getArrays = (arr: any, vals: any, key: string, nextListKey: string) => {
  let ob: any = { [nextListKey]: [], values: [] };
  if (!arr || arr.length === 0 || !nextListKey) return ob;
  // console.log("check vals", vals, arr, key);
  arr.map((item: any) => {
    if (Array.isArray(vals) && vals.includes(item[key])) {
      ob = {
        values: [...ob.values, item[key]],
        [nextListKey]: item[nextListKey]
          ? [...ob[nextListKey], ...item[nextListKey]]
          : [],
      };
    } else if (item[key] === vals) {
      ob = {
        values: item[key],
        [nextListKey]: item[nextListKey],
      };
    }
  });
  return ob;
};

const searchAndGetObj = (
  obj: any,
  value: string | number,
  key: string,
  listKey: string,
  nextListKey: string
) => {
  const arr = obj[listKey];
  let ob = { [nextListKey]: [] };
  if (!arr || arr.length === 0) return ob;
  arr?.map((item: any) => {
    if (value && Array.isArray(value) && value?.includes(item[key])) {
      ob = {
        ...item,
        [nextListKey]: [...ob[nextListKey], ...item[nextListKey]],
      };
    } else if (item[key] === value) {
      ob = item;
    }
  });
  return ob;
};

const checkFieldKeyIsEmpty=(keyValue)=>{
    if(keyValue==undefined){
      return true
    }else{
      return isEmptyObject(keyValue)
    }
}
function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
